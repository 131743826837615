import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'



// 手机端
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant);



Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')