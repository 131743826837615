<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    console.log(11);
  },
  watch: {
    $route: function (to) {
      console.log(to);
      if (to.name.indexOf("_m") > 0 && !this._isMobile()) {
        console.log(to.name.split("_m")[0]);
        this.$router.replace(to.name.split("_m")[0]);
      } else if (to.name.indexOf("_m") < 0 && this._isMobile()) {
        console.log(to.name + "_m");
        this.$router.replace(to.name + "_m");
      }
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 公共样式 */
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb38 {
  padding-bottom: 38px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb45 {
  padding-bottom: 45px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb55 {
  padding-bottom: 55px;
}
.pt12 {
  padding-top: 12px;
}
.pt15 {
  padding-top: 15px;
}
.pt45 {
  padding-top: 45px;
}
.pt50 {
  padding-top: 50px;
}
.pt70 {
  padding-top: 70px;
}
.pt90 {
  padding-top: 90px;
}
.pt126 {
  padding-top: 126px;
}
.pt250 {
  padding-top: 250px;
}
.pl20 {
  padding-left: 20px;
}
.pl50 {
  padding-left: 50px;
}
.pr20 {
  padding-right: 20px;
}
.pr50 {
  padding-right: 50px;
}
.fs12 {
  font-size: 12px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs26 {
  font-size: 26px;
}
.fs28 {
  font-size: 28px;
}
.fs30 {
  font-size: 30px;
}
.fs40 {
  font-size: 40px;
}
.fs42 {
  font-size: 42px;
}
.lh25 {
  line-height: 25px;
}
.lh30 {
  line-height: 30px;
}
.lh33 {
  line-height: 33px;
}

.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr52 {
  margin-right: 52px;
}
.mr56 {
  margin-right: 56px;
}
.mr60 {
  margin-right: 60px;
}
.mr80 {
  margin-right: 80px;
}
.ml100 {
  margin-left: 100px;
}

.tac {
  text-align: center;
}
.tal {
  text-align: left;
}
.fl {
  float: left;
}

.cb6 {
  color: #b6b6b9;
}
.c92 {
  color: #929292;
}
.c293 {
  color: #293a6c;
}
.c334 {
  color: #33497f;
}
.c5f8 {
  color: #5f8be5;
}
.c093 {
  color: #0093f7;
}
.c404 {
  color: #404040;
}
.c8e8 {
  color: #8e8e8e;
}
.white {
  color: #ffffff;
}

.all {
  width: 1100px;
}
.m0a {
  margin: 0 auto;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.t4 {
  top: 4px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: "clear";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
</style>
