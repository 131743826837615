import Vue from 'vue'
import VueRouter from 'vue-router'
import MRouter from './mobile'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home'
    }, {
        path: '/home',
        name: 'home',
        // component: resolve => require(['@/views/home.vue'], resolve),
        component: resolve => require(['@/views/product/erp.vue'], resolve),
        meta: {
            title: '首页'
        },
    }, {
        path: '/product/erp',
        name: 'erp',
        component: resolve => require(['@/views/product/erp.vue'], resolve),
        meta: {
            title: '生产ERP'
        },
    },
    {
        path: '/product/retail',
        name: 'retail',
        component: resolve => require(['@/views/product/retail.vue'], resolve),
        meta: {
            title: '零售系统'
        },
    }, {
        path: '/product/cater',
        name: 'cater',
        component: resolve => require(['@/views/product/cater.vue'], resolve),
        meta: {
            title: '点餐系统'
        },
    }, {
        path: '/product/wx',
        name: 'wx',
        component: resolve => require(['@/views/product/wx.vue'], resolve),
        meta: {
            title: '小程序'
        },
    }, {
        path: '/plan/supermarket',
        name: 'supermarket',
        component: resolve => require(['@/views/plan/supermarket.vue'], resolve),
        meta: {
            title: '便利商超方案'
        },
    }, {
        path: '/download',
        name: 'download',
        component: resolve => require(['@/views/download.vue'], resolve),
        meta: {
            title: '下载'
        },
    },
    {
        path: '/new_index',
        name: 'new_index',
        component: resolve => require(['@/views/news/new_index.vue'], resolve),
        meta: {
            title: '资讯'
        },
    },
    {
        path: '/about_us',
        name: 'about_us',
        component: resolve => require(['@/views/about_us.vue'], resolve),
        meta: {
            title: '关于我们'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: [...routes, ...MRouter]
})

export default router